import * as Toast from '@radix-ui/react-toast'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from '@remix-run/react'
import type { LinksFunction } from '@remix-run/server-runtime'
import { captureRemixErrorBoundaryError } from '@sentry/remix'
import { json, type MetaFunction, type LoaderFunctionArgs } from '@vercel/remix'
import { useEffect, useState } from 'react'
import { getToast } from 'remix-toast'

import share from '~/assets/images/share.jpg'

import { ToastNotification } from './components/ToastNotification'
import { ErrorPage } from './features/errors/pages/ErrorPage'
import { PageViewTracking } from './features/tracking/components/PageViewTracking'
import stylesheet from './index.css?url'
import { absoluteUrl } from './utils/absoluteUrl'

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: stylesheet },
]

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { toast, headers } = await getToast(request)

  return json(
    {
      toast,
      url: request.url,
      shareImage: absoluteUrl(share),
    },
    { headers }
  )
}

const title = 'Side School Platform'
const description =
  'Keep learning with Side School. Log in and access your dashboard, challenges and workshops.'

export const meta: MetaFunction<typeof loader> = ({ data }) => [
  { title },
  {
    name: 'description',
    content: description,
  },
  {
    property: 'og:title',
    content: title,
  },
  {
    property: 'og:description',
    content: description,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  ...(data
    ? [
        {
          property: 'og:url',
          content: data.url,
        },
        {
          property: 'og:image',
          content: data.shareImage,
        },
      ]
    : []),
  {
    name: 'twitter:card',
    content: 'summary_large_image',
  },
  {
    name: 'twitter:title',
    content: title,
  },
  {
    name: 'twitter:description',
    content: description,
  },
]

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* FONTS */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="https://use.typekit.net/bhk1mmg.css" />
        {/* END FONTS */}
        {/* FAVICONS */}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="theme-color" content="#000000" />
        {/* END FAVICONS */}
        <Meta />
        <Links />
      </head>
      <body className="bg-neutral-900">
        <PageViewTracking />
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export const ErrorBoundary = () => {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <ErrorPage code={error.status} message="You lost your way!" />
    } else if (error.status >= 500) {
      return (
        <ErrorPage
          code={error.status}
          message="Something went wrong. We were notified and are working on fixing the issue."
        />
      )
    } else {
      return <ErrorPage code={error.status} message="Oops!" />
    }
  }

  // Easier debugging if the team happens to see the error
  console.error(error)

  return (
    <ErrorPage
      code="unknown_error"
      message="Something went wrong. We were notified and are working on fixing the issue."
    />
  )
}

export default function App() {
  return (
    <ToastProvider>
      <Outlet />
    </ToastProvider>
  )
}

function ToastProvider({ children }: { children: React.ReactNode }) {
  const { toast } = useLoaderData<typeof loader>()
  const [isToastOpen, setIsToastOpen] = useState(false)

  useEffect(() => {
    setIsToastOpen(!!toast)
  }, [toast])

  return (
    <Toast.Provider>
      {children}
      {toast ? (
        <ToastNotification
          open={isToastOpen}
          onOpenChange={setIsToastOpen}
          title={toast.message}
          description={toast.description}
        />
      ) : null}
      <Toast.Viewport className="fixed bottom-5 left-1/2 flex w-full max-w-xs -translate-x-1/2 flex-col gap-2 p-4" />
    </Toast.Provider>
  )
}
