import { useLocation } from '@remix-run/react'
import { posthog } from 'posthog-js'
import { useEffect } from 'react'

export function PageViewTracking() {
  const location = useLocation()

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      posthog.capture('$pageview')
    }
  }, [location])

  return null
}
