import * as Toast from '@radix-ui/react-toast'
import classNames from 'classnames'

interface ToastNotificationProps {
  open: boolean
  onOpenChange?(open: boolean): void
  title: string
  description?: string
}

export const ToastNotification = ({
  open,
  onOpenChange,
  title,
  description,
}: ToastNotificationProps) => {
  return (
    <Toast.Root
      open={open}
      onOpenChange={onOpenChange}
      className={classNames({
        'animate-slide-in': open,
        'animate-slide-out': !open,
      })}
    >
      <div className="max-w-[280px] rounded-md border border-[#5E6160] bg-neutral-800 px-4 py-3 shadow-toaster">
        <div className="flex justify-between">
          <Toast.Title className="font-lato text-sm font-medium text-white">
            {title}
          </Toast.Title>
          <Toast.Action asChild altText="Close">
            <button className="ml-2 text-white" type="button">
              <img src="/close.svg" alt="" className="ml-1" />
            </button>
          </Toast.Action>
        </div>
        {description ? (
          <Toast.Description className="font-lato text-sm font-normal text-neutral-200">
            {description}
          </Toast.Description>
        ) : null}
      </div>
    </Toast.Root>
  )
}
